import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Outlet } from "react-router-dom";
import './App.css';
import Header from "./common/header";
import Footer from "./common/footer";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './assets/css/style.css'; // Corrected path
import './assets/css/responsive.css'; // Corrected path
import ScrollToTopButton from "./common/ScrollToTopButton";

import Home from "./pages/home/home";
import PrivacyPolicy from "./pages/common/privacyPolicy";
import Imprint from "./pages/common/imPrint";
import AboutUs from "./pages/aboutus/aboutUs";
import TheBeebox from "./pages/beebox/theBeebox";
import Sustainability from "./pages/sustainability/sustainability";

// const Home = React.lazy(() => import("./pages/home/home"));
// const PrivacyPolicy = React.lazy(() => import("./pages/common/privacyPolicy"));
// const Imprint = React.lazy(() => import("./pages/common/imPrint"));
// const AboutUs = React.lazy(() => import("./pages/aboutus/aboutUs"));
// const TheBeebox = React.lazy(() => import("./pages/beebox/theBeebox"));
// const Sustainability = React.lazy(() => import("./pages/sustainability/sustainability"));

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/*" element={<Layout />}>
          <Route index element={<React.Suspense fallback={<>...</>}><Home /></React.Suspense>} />
          <Route path="/*" element={<React.Suspense fallback={<>...</>}><Home /></React.Suspense>} />
          <Route path="privacy-policy" element={<React.Suspense fallback={<>...</>}><PrivacyPolicy /></React.Suspense>} />
          <Route path="imprint" element={<React.Suspense fallback={<>...</>}><Imprint /></React.Suspense>} />
          <Route path="about-us" element={<React.Suspense fallback={<>...</>}><AboutUs /></React.Suspense>} />
          <Route path="the-beebox" element={<React.Suspense fallback={<>...</>}><TheBeebox /></React.Suspense>} />
          <Route path="sustainability" element={<React.Suspense fallback={<>...</>}><Sustainability /></React.Suspense>} />
        </Route>
      </Routes>
      <ScrollToTopButton />
    </Router>
  );
}
export default App;

function Layout() {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <React.Suspense fallback={<div>Loading...</div>}>
        <Header expanded={expanded} setExpanded={setExpanded} />
        <Outlet />
        <Footer setExpanded={setExpanded} />
      </React.Suspense>
    </>
  );
}
